<template>
  <Frame>
    <h1>Política de Privacidad</h1>
    <p>
      Si estás aquí es porque quieres saber más sobre las obligaciones y
      derechos que te corresponden como usuario de esta web
      <b>https://micafetera.net</b> y eso está muy bien. Nuestro deber es
      informarte y el tuyo estar debidamente informado.
    </p>
    <p>
      En esta Política de Privacidad te informaremos con total transparencia
      sobre la finalidad de este sitio web y todo lo que afecta a los datos que
      nos facilites, así como de las obligaciones y derechos que te
      corresponden.
    </p>
    <p>
      Para empezar, debes saber que este sitio web se adapta a la normativa
      vigente en relación con la protección de datos, lo que afecta a los datos
      personales que nos facilites con tu consentimiento expreso y a las cookies
      que utilizamos para que este sitio web funcione correctamente y pueda
      desarrollar su actividad.
    </p>
    <p>
      Concretamente, esta web se ajusta al cumplimiento de las siguientes
      normativas:
    </p>
    <p>
      El
      <b
        >RGPD (Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de
        27 de abril de 2016 relativo a la protección de las personas físicas)</b
      >
      que es la nueva normativa de la Unión Europea que unifica la regulación
      del tratamiento de los datos personales en los distintos países de la UE.
    </p>
    <p>
      La
      <b
        >LOPD (Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos
        de Carácter Personal y Real Decreto 1720/2007, de 21 de diciembre, el
        Reglamento de desarrollo de la LOPD)</b
      >
      que regula el tratamiento de los datos personales y las obligaciones que
      debemos asumir los responsables de una web o un blog a la hora de
      gestionar esta información.
    </p>
    <p>
      La
      <b
        >LSSI (Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
        Información y Comercio Electrónico)</b
      >
      que regula las transacciones económicas mediante medios electrónicos, como
      es el caso de este blog.
    </p>
    <h2>DATOS DE IDENTIFICACIÓN</h2>
    <p>
      Los datos personales que nos facilites, siempre con tu consentimiento
      expreso, se almacenarán y tratarán con los fines previstos y descritos a
      continuación en esta Política de Privacidad, hasta el momento en que nos
      pidas que los eliminemos.
    </p>
    <p>
      Te informamos de que esta Política de Privacidad puede ser modificada en
      cualquier momento, con el fin de adaptarla a novedades legislativas o
      cambios en nuestras actividades, siendo vigente la que en cada momento se
      encuentre publicada en la web. Dicha modificación se te notificará antes
      de su aplicación.
    </p>
    <h2>CONDICIONES DE USO</h2>
    <p>
      Debes saber, para tu tranquilidad, que siempre te solicitaremos tu
      consentimiento expreso para recabar tus datos con la correspondiente
      finalidad especificada en cada caso, lo que implica que, en caso de
      otorgar ese consentimiento, has leído y aceptado esta Política de
      Privacidad.
    </p>
    <p>
      En el momento en que accedas y utilices esta web, asumes tu condición de
      usuario con tus correspondientes derechos y obligaciones.
    </p>
    <p>
      Si eres mayor de 13 años, podrás registrarte como usuario en este sitio
      web sin el previo consentimiento de tus padres o tutores.
    </p>
    <p>
      Si eres menor de 13 años, necesitarás el consentimiento de tus padres o
      tutores para el tratamiento de tus datos personales.
    </p>
    <h2>REGISTRO Y FINALIDAD DE TUS DATOS</h2>
    <p>
      En función del formulario o sección a la que accedas, te solicitaremos
      exclusivamente los datos necesarios con las finalidades descritas a
      continuación. En todo momento, deberás dar tu consentimiento expreso,
      cuando te solicitemos información personal con las siguientes finalidades:
    </p>
    <li>
      Las finalidades concretas que particularmente se indiquen en cada una de
      las páginas o secciones donde aparezca el formulario de registro o
      contacto electrónico.
    </li>
    <li>
      Con carácter general, para atender tus solicitudes, comentarios, consultas
      o cualquier tipo de petición que realices como usuario a través de
      cualquiera de las formas de contacto que ponemos a tu disposición
    </li>
    <li>
      Para informarte sobre consultas, peticiones, actividades, productos,
      novedades y/o servicios; vía e-mail, fax, Whatsapp, Skype, teléfono
      proporcionado, sms y mms.
    </li>
    <li>
      Para enviarte comunicaciones comerciales o publicitarias a través de
      cualquier otro medio electrónico o físico, que posibilite realizar
      comunicaciones.
    </li>
    <p>
      Estas comunicaciones, siempre serán relacionadas con nuestros productos,
      servicios, novedades o promociones, así como aquellos productos o
      servicios que podamos considerar de tu interés y que puedan ofrecer
      colaboradores, empresas o “partners” con los que mantengamos acuerdos de
      promoción o colaboración comercial.
    </p>
    <p>
      De ser así, te garantizamos que estos terceros nunca tendrán acceso a tus
      datos personales, con las excepciones reflejadas a continuación, siendo en
      todo caso estas comunicaciones realizadas por parte del titular de la web.
    </p>
    <p>
      Este sitio web muestra productos de afiliado de terceros. Concretamente de
      <b>AMAZON</b>.
    </p>
    <p>
      Esto quiere decir que al hacer clic en “Comprar Ahora” o similar, se te
      redirigirá a la página donde se ofertan los productos.
    </p>
    <p>
      En este caso, debes saber que únicamente proporcionamos y facilitamos los
      enlaces a las páginas y/o plataformas de estos terceros donde pueden
      adquirirse los productos que mostramos, en aras a facilitar la búsqueda y
      fácil adquisición de los mismos.
    </p>
    <p>
      Estas páginas enlazadas y pertenecientes a terceros no han sido revisadas
      ni son objeto de controles, ni recomendación por nuestra parte, por lo que
      en ningún caso <b>https://micafetera.net</b> será considerada responsable
      de los contenidos de estos sitios web, de las responsabilidades derivadas
      de su uso en todos los ámbitos, ni por las medidas que se adopten
      relativas a la privacidad del usuario, tratamiento de sus datos de
      carácter personal u otras que pudieran establecerse.
    </p>
    <p>
      Por todo ello, te recomendamos la lectura detenida y anticipada de todas
      las condiciones de uso, condiciones de compra, políticas de privacidad,
      avisos legales y/o similares de estos sitios enlazados antes de proceder a
      la adquisición de estos productos o uso de las webs.
    </p>
    <h2>EXACTITUD Y VERACIDAD DE LOS DATOS</h2>
    <p>
      Como usuario, eres el único responsable de la veracidad y modificación de
      los datos que remitas a <b>https://micafetera.net</b>, exonerándonos de
      cualquier responsabilidad al respecto.
    </p>
    <p>
      Es decir, a ti te corresponde garantizar y responder en cualquier caso de
      la exactitud, vigencia y autenticidad de los datos personales facilitados,
      y te comprometes a mantenerlos debidamente actualizados.
    </p>
    <p>
      De acuerdo a lo expresado en esta Política de Privacidad, aceptas
      proporcionar información completa y correcta en el formulario de contacto
      o suscripción.
    </p>
    <h2>BAJAS DE SUSCRIPCIÓN Y DERECHO DE REVOCACIÓN</h2>
    <p>
      Como titular de los datos que nos hayas facilitado, podrás ejercer en
      cualquier momento tus derechos de acceso, rectificación, cancelación y
      oposición, enviándonos un correo electrónico a lukarddeveloper@gmail.com y
      adjuntándonos una fotocopia de tu documento de identidad como prueba
      válida.
    </p>
    <p>
      Igualmente, podrás darte de baja en cualquier momento para dejar de
      recibir nuestra newsletter o cualquier otra comunicación comercial,
      directamente desde el mismo email que hayas recibido o enviándonos un
      correo a lukarddeveloper@gmail.com.
    </p>
    <h2>ACCESO A DATOS POR CUENTA DE TERCEROS</h2>
    <p>
      Para poder prestar servicios estrictamente necesarios para el
      funcionamiento y desarrollo de las actividades de este sitio web, te
      informamos de que compartimos datos con los siguientes prestadores de
      servicio bajo sus correspondientes condiciones de privacidad.
    </p>
    <p>
      Puedes tener la total tranquilidad de que estos terceros no podrán
      utilizar dicha información para ningún otro fin que no esté regulado
      específicamente en nuestras relaciones con ellos en virtud de la normativa
      aplicable en materia de protección de datos de carácter personal.
    </p>
    <p>
      Este sitio web está alojado en <b>https://github.com/</b>, con marca
      comercial <b>GitHub Inc.</b>, que proporciona los servicios de hosting
      para que puedas acceder y navegar por nuestro sitio. Puedes consultar la
      política de privacidad y demás aspectos legales de esta empresa en el
      siguiente enlace:
      <b
        >https://docs.github.com/en/free-pro-team@latest/github/site-policy/github-privacy-statement</b
      >.
    </p>
    <p>
      Nuestra web utiliza servidores publicitarios con el fin de facilitar los
      contenidos comerciales que visualizas en nuestras páginas. Dichos
      servidores publicitarios utilizan cookies que le permiten adaptar los
      contenidos publicitarios a los perfiles demográficos de los usuarios:
    </p>
    <h3>Google Analytics:</h3>
    <p>
      Google Analytics es un servicio analítico de web prestado por Google,
      Inc., una compañía de Delaware cuya oficina principal está en 1600
      Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos
      (“Google”).
    </p>
    <p>
      Google Analytics utiliza “cookies”, que son archivos de texto ubicados en
      tu ordenador, para ayudar al website a analizar el uso que hacen los
      usuarios del sitio web.
    </p>
    <p>
      La información que genera la cookie acerca de tu uso del website
      (incluyendo tu dirección IP) será directamente transmitida y archivada por
      Google. Google usará esta información por cuenta nuestra con el propósito
      de seguir la pista de su uso del website, recopilando informes de la
      actividad del website y prestando otros servicios relacionados con la
      actividad del website y el uso de Internet.
    </p>
    <p>
      Google podrá transmitir dicha información a terceros cuando así se lo
      requiera la legislación, o cuando dichos terceros procesen la información
      por cuenta de Google. Google no asociará su dirección IP con ningún otro
      dato del que disponga.
    </p>
    <p>
      Como usuario, y en ejercicio de tus derechos, puedes rechazar el
      tratamiento de los datos o la información rechazando el uso de cookies
      mediante la selección de la configuración apropiada de tu navegador, sin
      embargo, debes saber que si lo hace puede que no puedas usar la plena
      funcionabilidad de este sitio web.
    </p>
    <p>
      Al utilizar esta web, de acuerdo a la información facilitada en esta
      Política de Privacidad, aceptas el tratamiento de datos por parte de
      Google en la forma y para los fines indicados.
    </p>
    <p>
      Para más información, puedes consultar la política de privacidad de Google
      en
      <a href="https://www.google.com/intl/es/policies/privacy/" target="_blank"
        >https://www.google.com/intl/es/policies/privacy/</a
      >.
    </p>
    <h3>Google Adsense:</h3>
    <p>
      Google, como proveedor asociado, utiliza cookies para publicar anuncios en
      este sitio web. Puedes inhabilitar el uso de la cookie de DART a través
      del anuncio de Google y accediendo a la política de privacidad de la red
      de contenido:
      <a href="https://www.google.com/intl/es/policies/privacy/" target="_blank"
        >https://www.google.com/intl/es/policies/privacy/</a
      >.
    </p>
    <p>
      Google utiliza empresas publicitarias asociadas para publicar anuncios
      cuando visita nuestro sitio web. Es posible que estas empresas usen la
      información que obtienen de tus visitas a este y otros sitios web (sin
      incluir tu nombre, dirección, dirección de correo electrónico o número de
      teléfono) para ofrecerte anuncios sobre productos y servicios que te
      resulten de interés.
    </p>
    <p>
      Al utilizar esta web, consientes el tratamiento de datos por parte de
      Google en la forma y para los fines indicados.
    </p>
    <p>
      Si quieres saber más sobre el uso de cookies y las prácticas de
      recopilación de información y los procedimientos de aceptación o rechazo,
      consulta nuestra
      <router-link :to="{ name: 'Cookies' }">POLÍTICA DE COOKIES</router-link>.
    </p>
    <h3>MEDIDAS DE SEGURIDAD</h3>
    <p>
      Se han adoptado todas las medidas técnicas y de organización necesaria
      para garantizar la seguridad e integridad de los datos de carácter
      personal que trate, así como para evitar su pérdida, alteración y/o acceso
      por parte de terceros no autorizados.
    </p>
    <p>
      Te recordamos que, para más información, puedes consultar nuestras páginas
      de <router-link :to="{ name: 'Legal' }">Aviso Legal</router-link> y
      <router-link :to="{ name: 'Cookies' }">Política de Cookies</router-link>.
    </p>
  </Frame>
</template>

<script>
import Frame from '@/components/Frame.vue';

export default {
  name: 'Privacy',
  components: {
    Frame,
  },
};
</script>

<style scoped>
p,
li {
  line-height: 150%;
}
</style>
